




























import {
  computed,
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import { SfAccordion } from '@storefront-ui/vue';

import HTMLContent from '~/components/HTMLContent.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { Product } from '~/modules/catalog/product/types';
// import {
//   useReview,
// } from '~/modules/review/composables/useReview';
// import {
//   getReviewAuthor,
//   getReviewDate,
//   getReviewId,
//   getReviewMessage,
//   getReviewRating,
// } from '~/modules/review/getters/reviewGetters';
// import { usePageStore } from '~/stores/page';

export default defineComponent({
  name: 'ProductAccordion',
  components: {
    HTMLContent,
    SfAccordion,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product | null>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { locale } = useI18n();
    // const { routeData } = usePageStore();
    // const reviews = ref(null);
    // const isReviewsLoading = ref(true);

    // const { search: searchReviews, addReview } = useReview();

    // const getSearchQuery = () => ({
    //   filter: {
    //     sku: {
    //       eq: routeData.sku,
    //     },
    //   },
    // });

    // const fetchReviews = async (query = getSearchQuery()) => {
    //   const productReviews = await searchReviews(query);
    //   const baseReviews = Array.isArray(productReviews)
    //     ? productReviews[0]
    //     : productReviews;

    //   reviews.value = reviewGetters.getItems(baseReviews);
    // };

    // let lastReviewsQuery = '';

    // const newQuery = getSearchQuery();
    // const stringNewQuery = JSON.stringify(newQuery);
    // if (lastReviewsQuery !== stringNewQuery) {
    //   lastReviewsQuery = stringNewQuery;
    //   // fetchReviews(newQuery);
    //   isReviewsLoading.value = false;
    // }

    const productShortDescription = computed(() => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      let short_description = props.product?.short_description?.html;
      short_description = short_description?.replace(/\r\n/g, '<br>');
      return short_description || '';
    });
    const productDescription = computed(
      () => {
        if (props.product?.description?.html) {
          return props.product.description.html.replace('```html\n', '').replace('```', '');
        }
        return '';
      }
    );

    // const successAddReview = async (reviewData: UseReviewAddReviewParams) => {
    //   await addReview(reviewData);
    //   document.querySelector('#tabs').scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'end',
    //   });
    // };

    return {
      locale,
      // reviews,
      // isReviewsLoading,
      // getReviewId,
      // getReviewAuthor,
      // getReviewDate,
      // getReviewMessage,
      // getReviewRating,
      productShortDescription,
      productDescription,
      // successAddReview,
    };
  },
});
