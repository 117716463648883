








































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRef,
  useContext,
} from '@nuxtjs/composition-api';
import {
  SfAddToCart,
  SfAlert,
  SfButton,
  SfCarousel,
  SfGallery,
  SfHeading,
  SfLoader,
  SfPrice,
} from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';

import AddToWishlist from '~/components/AddToWishlist.vue';
import { useUser } from '~/modules/account/composables/useUser';
import ProductAccordion from '~/modules/catalog/product/components/accordion/ProductAccordion.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import {
  TabsConfig,
  useProductTabs,
} from '~/modules/catalog/product/composables/useProductTabs';
import {
  getName as getProductName,
  getPrice as getProductPrice,
} from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';
import { useCart } from '~/modules/checkout/composables/useCart';
import {
  getAverageRating,
  getTotalReviews,
} from '~/modules/review/getters/reviewGetters';
import useWishlist from '~/modules/wishlist/composables/useWishlist';

export default defineComponent({
  name: 'SimpleProduct',
  components: {
    LazyHydrate,
    SfAddToCart,
    SfButton,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfAlert,
    AddToWishlist,
    ProductAccordion,
    SfCarousel,
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const qty = ref(1);
    const product = toRef(props, 'product');
    const {
      addItem,
      error: cartError,
      loading: isCartLoading,
      canAddToCart,
    } = useCart();
    const {
      $config: { currentSite },
      app
    } = useContext();
    const { isDesktop } = app.$device;

    const { productGallery, imageSizes } = useProductGallery(product);
    const { isAuthenticated } = useUser();
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();

    // const productShortDescription = computed(
    //   () => props.product?.short_description?.html || ''
    // );
    const productPrice = computed(() => getProductPrice(props.product).regular);
    const productSpecialPrice = computed(
      () => getProductPrice(props.product).special
    );
    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));

    const addToCartError = computed(() => {
      let errorMessage = '';
      if (
        cartError.value?.addItem?.message?.includes('qty is not available') &&
        currentSite === 'cn'
      ) {
        errorMessage = '库存不足,无法加入购物车！';
      } else {
        errorMessage = cartError.value?.addItem?.message;
      }
      return errorMessage;
    });

    return {
      addItem,
      addItemToWishlist: addOrRemoveItem,
      averageRating,
      totalReviews,
      canAddToCart,
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      isCartLoading,
      // productShortDescription,
      productGallery,
      getProductName,
      productPrice,
      productSpecialPrice,
      qty,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig,
      addToCartError,
      currentSite,
      isDesktop,
    };
  },
});
