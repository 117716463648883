
















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'AddToWishlist',
  components: {
    SfButton,
    SvgImage,
  },
  props: {
    component: {
      type: String,
      default: 'span',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    wishlistIcon: {
      type: String,
      default: 'unwish_icon',
    },
    isInWishlistIcon: {
      type: String,
      default: 'wished_icon',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const actionText = computed(() =>
      props.isInWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'
    );

    return {
      actionText,
    };
  },
});
