var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product"},[(_vm.isDesktop)?_c('SfLoader',{staticClass:"loading--product-gallery loading---d-product-gallery",attrs:{"loading":_vm.isFetching}},[_c('SfGallery',{staticClass:"product__gallery simpleproduct_gallery",attrs:{"images":_vm.productGallery,"image-width":500,"image-height":500,"enable-zoom":false,"image-tag":"nuxt-img","thumb-image-tag":"nuxt-img","thumb-nuxt-img-config":{
        fit: 'contain',
      }}})],1):_c('SfLoader',{staticClass:"loading--product-gallery",attrs:{"loading":_vm.isFetching}},[(_vm.productGallery && _vm.productGallery.length > 0)?_c('SfCarousel',{staticClass:"product__carousel",attrs:{"data-cy":"product-carousel","settings":{
        type: 'carousel',
        rewind: true,
        startAt: 0,
        perView: 1,
        slidePerPage: true,
        autoplay: false,
        peek: 0,
        breakpoints: { 1023: { perView: 1, peek: 0 } },
      }}},_vm._l((_vm.productGallery),function(image,i){return _c('SfCarouselItem',{key:i,staticClass:"carousel__item"},[_c('img',{staticClass:"smartphone-only",attrs:{"src":image.big.url,"alt":image.alt || 'uninned',"width":359,"height":359}})])}),1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"product__info"},[_c('div',{staticClass:"product__header"},[_c('SfHeading',{staticClass:"sf-heading--no-underline sf-heading--left",attrs:{"title":_vm.getProductName(_vm.product),"level":3}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.product && (_vm.product.brands === 3307 || _vm.product.brands === 293)),expression:"product && (product.brands === 3307 || product.brands === 293)"}],staticClass:"product__delivery_time"},[_c('p',[_vm._v(_vm._s(_vm.$t('Delivery time'))+" : "+_vm._s(_vm.$t('15-21 working days')))])]),_vm._v(" "),_c('div',{staticClass:"product__price-and-rating"},[_c('SfPrice',{attrs:{"regular":_vm.$fc(_vm.productPrice),"special":_vm.productSpecialPrice && _vm.$fc(_vm.productSpecialPrice)}})],1),_vm._v(" "),_c('div',[_c('div',{staticClass:"product__add-to-cart-wish flex-space-between"},[_c('SfAddToCart',{staticClass:"product__add-to-cart",attrs:{"disabled":_vm.isCartLoading || _vm.isFetching},on:{"click":function($event){return _vm.addItem({ product: _vm.product, quantity: _vm.qty })}},scopedSlots:_vm._u([{key:"add-to-cart-btn",fn:function(){return [(_vm.product && _vm.product.stock_status === 'OUT_OF_STOCK')?_c('SfButton',{staticClass:"sf-add-to-cart__button",attrs:{"disabled":""}},[_c('div',[_vm._v(_vm._s(_vm.$t('Out of stock')))])]):_c('SfButton',{staticClass:"sf-add-to-cart__button",attrs:{"disabled":_vm.isCartLoading || !_vm.canAddToCart(_vm.product, _vm.qty) || _vm.isFetching},on:{"click":function($event){return _vm.addItem({ product: _vm.product, quantity: _vm.qty })}}},[_c('SfLoader',{staticClass:"flex-center loader",class:{
                  loader:
                    _vm.isCartLoading ||
                    !_vm.canAddToCart(_vm.product, _vm.qty) ||
                    _vm.isFetching,
                },attrs:{"loading":_vm.isCartLoading || !_vm.canAddToCart(_vm.product, _vm.qty) || _vm.isFetching}},[_c('div',[_vm._v(_vm._s(_vm.$t('Add to cart')))])])],1)]},proxy:true}]),model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}}),_vm._v(" "),_c('AddToWishlist',{attrs:{"is-in-wishlist":_vm.isInWishlist,"is-show":true},on:{"addToWishlist":function($event){return _vm.addItemToWishlist({ product: _vm.product })}}})],1),_vm._v(" "),_c('SfAlert',{staticClass:"product__add-to-cart-error",style:({ visibility: !!_vm.addToCartError ? 'visible' : 'hidden' }),attrs:{"message":_vm.$t(_vm.addToCartError),"type":"danger"}})],1),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('ProductAccordion',{attrs:{"product":_vm.product}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }